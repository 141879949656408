var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('StandardCardHeader',{attrs:{"go-to-link":'admin-user-list',"title":'Create user',"button-text":'Close',"show-action-button":true,"link-button-color":'error'},on:{"action":function($event){return _vm.save()}}}),_c('v-form',{ref:"form",staticClass:"ma-0 pt-6",attrs:{"lazy-validation":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('v-text-field',{staticClass:"w-full",attrs:{"rules":[_vm.validator.required],"dense":"","outlined":"","label":_vm.$t('First name')},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.user.first_name),callback:function ($$v) {_vm.$set(_vm.user, "first_name", $$v)},expression:"user.first_name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('v-text-field',{staticClass:"w-full",attrs:{"rules":[_vm.validator.required],"dense":"","outlined":"","label":_vm.$t('Last name')},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('v-text-field',{staticClass:"w-full",attrs:{"rules":[_vm.validator.required],"dense":"","outlined":"","label":_vm.$t('login.username')},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('v-text-field',{staticClass:"w-full",attrs:{"dense":"","outlined":"","label":_vm.$t('Phone')},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('v-text-field',{staticClass:"w-full",attrs:{"rules":[_vm.validator.required, _vm.validator.passwordValidator],"type":"password","dense":"","outlined":"","label":_vm.$t('Password')},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('v-text-field',{staticClass:"w-full",attrs:{"type":"password","rules":[_vm.validator.required, _vm.validator.passwordValidator],"dense":"","outlined":"","label":_vm.$t('Password confirmation')},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.user.password_confirmation),callback:function ($$v) {_vm.$set(_vm.user, "password_confirmation", $$v)},expression:"user.password_confirmation"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('v-select',{staticClass:"w-full",attrs:{"rules":[_vm.validator.required],"items":_vm.userTypeOptions,"item-value":"key","item-text":function (item) { return _vm.$t(item.name); },"dense":"","outlined":"","label":_vm.$t('Type')},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.user.user_type),callback:function ($$v) {_vm.$set(_vm.user, "user_type", $$v)},expression:"user.user_type"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('v-select',{staticClass:"w-full",attrs:{"rules":[_vm.validator.required],"dense":"","outlined":"","items":_vm.statusOptions,"item-text":function (item) { return _vm.$t(item.name); },"item-value":"key","label":_vm.$t('Status')},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.user.status),callback:function ($$v) {_vm.$set(_vm.user, "status", $$v)},expression:"user.status"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('v-select',{staticClass:"w-full mb-1",attrs:{"rules":[_vm.validator.required],"items":_vm.$store.getters.getStocks,"dense":"","outlined":"","label":_vm.$t('Location'),"hide-details":"","item-value":"id","item-text":"name"},model:{value:(_vm.user.location),callback:function ($$v) {_vm.$set(_vm.user, "location", $$v)},expression:"user.location"}})],1)],1)],1)],1),_c('StandardCardHeader',{attrs:{"go-to-link":'admin-user-list',"button-text":'Close',"show-action-button":true,"link-button-color":'error'},on:{"action":function($event){return _vm.save()}}})],1),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t('Close' ))+" ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }